<template>
    <div>
        <div>
            <el-page-header @back="goBack" content="下级用户">
            </el-page-header>
        </div>

        <!--内容-->
        <div style="padding-top: 50px" v-loading="loading">
            <el-table
                    :data="userList"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="registerMail"
                        label="用户邮箱"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="realName"
                        label="真实姓名"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>


                <el-table-column
                        prop="ipAddr"
                        label="注册IP"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="locationAddr"
                        show-overflow-tooltip
                        label="IP归属地"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="balanceNum"
                        show-overflow-tooltip
                        label="账户余额"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="assetsNum"
                        show-overflow-tooltip
                        label="投资总额"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="updateTime"
                        show-overflow-tooltip
                        label="最近操作"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="注册时间"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="userStatus"
                        show-overflow-tooltip
                        label="用户状态"
                        align="center">
                    <template slot-scope="scope">
                        <el-tag effect="dark" :type="scope.row.userStatus==1?'':'danger'">
                            {{scope.row.userStatus==1?'正常':'已封禁'}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                        align="center"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" @click="lahei(scope.row.id)" v-show="scope.row.userStatus==1">封禁</el-button>
                        <el-button type="text" @click="lahei(scope.row.id)" v-show="scope.row.userStatus==0">解封</el-button>
                        <el-button type="text" @click="deleteById(scope.row.id)">删除</el-button>
                        <el-button type="text" @click="toUserTeam(scope.row.id)">查看下级</el-button>
                        <el-button type="text" @click="toDetail(scope.row.id)">数据统计</el-button>
                        <el-button type="text" @click="resetPass(scope.row.id)">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <div style="padding-top: 50px;text-align: center">
            <el-pagination
                    class="pagiantion"
                    :total="total"
                    @current-change="handleSelectionChange"
                    :current-page="user.pageIndex"
                    :page-size="user.pageSize"
                    layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>

        <el-dialog
                title="统计详情"
                :visible.sync="centerDialogVisible"
                width="60%"
                :before-close="beforeClose"
                center>
            <div>
                <el-table
                        :data="recordList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="totalChongZhi"
                            label="总充值"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="totalTiXian"
                            label="总提现"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="chongzhiCount"
                            label="充值次数"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="tixianCount"
                            label="提现次数"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="totalProfit"
                            label="投资盈利"
                            align="center">
                    </el-table-column>
                    <el-table-column
                            prop="totalPromote"
                            label="推广盈利"
                            align="center">
                    </el-table-column>
                </el-table>
            </div>

        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "UserTeam",
        data(){
            return{
                userList:[],
                user:{
                    pageIndex:1,
                    pageSize:5,
                    userId:null
                },
                total:0,
                loading:false,
                recordList:[],
                centerDialogVisible:false
            }
        },
        methods:{
            resetPass(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/user/resetPass?id=' + id).then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success("已将登录密码和支付密码重置为123456")
                            this.findPage();
                        }
                    })
                })
            },
            beforeClose(){
                this.recordList=[]
                this.centerDialogVisible=false;
            },
            toDetail(id){
                this.recordList=[]
                //this.$router.replace('/liuLiangRecord?userId='+id)
                this.axios.get('/user/accountDetail?id='+id).then(resp=>{
                    if (resp.data.code==200){
                        this.recordList.push(resp.data.data)
                    }
                }).finally(()=>{
                    this.centerDialogVisible=true;
                })
            },
            deleteById(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading=true;
                    this.axios.get('/user/deleteById?id=' + id).then(resp => {
                        if (resp.data.code == 200) {
                            this.$message.success(resp.data.message)
                            this.findPage();
                        }
                    }).finally(()=>{
                        this.loading=false
                    })
                })
            },
            lahei(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/user/lahei?id='+id).then(resp=>{
                        if (resp.data.code==200){
                            this.$message.success(resp.data.message)
                            this.findTeam()
                        }
                    })
                })
            },
            toUserTeam(userId){
                this.user.userId=userId
                this.findTeam()
            },
            goBack(){
                this.$router.replace('/userList')
            },
            findTeam(){
                this.loading=true
                this.axios.post('/user/findTeam',this.user).then(resp=>{
                    if (resp.data.code==200){
                        this.userList = resp.data.data.data;
                        this.total = resp.data.data.total;
                    }
                }).finally(()=>{
                    this.loading=false
                })
            },
            handleSelectionChange(currentPage) {
                this.user.pageIndex = currentPage;
                this.findTeam();
            },
        },
        created() {
            this.user.userId= this.$route.query.userId
            this.findTeam()
        }
    }
</script>

<style scoped>

</style>
